<template>
  <div class="page-user-recipesview">
    <div class="my-page">
      <div class="my-title" style="margin-bottom:15px;">
        <div class="title-label">
          <span class="span">一周食谱</span>
          <span class="line"></span>
        </div>
      </div>
      <div class="my-card">
        <van-tabs v-model="active">
          <van-tab
            v-for="(item, i) in recipeList"
            :key="i"
            :title="returnWeekDay(i + 1)"
          >
            <!-- 作息进度 -->
            <div class="step-box">
              <template v-if="item && item.Recipes">
                <template>
                  <template v-if="item.Recipes.imageTextList.length > 0">
                    <img
                      src="../../../assets/home/newRecipes.png"
                      style="width: 100%;"
                    />
                    <!-- <img src="../../../assets/home/cat (1).png" style="width: 160px;height: 50px;"
											class="catImg" /> -->
                    <div class="recipeList">
                      <div
                        v-for="(obj, i) in item.Recipes.imageTextList"
                        :key="i"
                      >
                        <h4
                          style="position: absolute;writing-mode: vertical-rl;left: -24px;color: #47AFA7;display: inline-block;"
                        >
                          {{ obj.MealsTypeName }}
                        </h4>
                        <img
                          src="../../../assets/home/breakfast.png"
                          v-if="i == 0"
                        />
                        <img
                          src="../../../assets/home/lunch.png"
                          v-else-if="i == 1"
                        />
                        <img src="../../../assets/home/dinner.png" v-else />
                        <h3 class="step-times">
                          {{ obj.RecipesContent }}
                        </h3>
                        <!-- <p class="step-img">
						    <van-image
						      v-for="(img, k) in obj.AccessoryPostList"
						      :key="k"
						      width="100"
						      height="74"
						      lazy-load
						      :src="img.url"
						      @click="
						        () => {
						          images = [];
						          images.push(img.url);
						          imgShow = true;
						        }
						      "
						      style="margin-right: 10px;margin-bottom: 10px;border-radius: 6px;overflow: hidden;display: inline-block;"
						    />
						  </p> -->
                      </div>
                    </div>
                  </template>
                  <van-empty description="暂无数据" v-else />
                </template>
                <!-- <template v-if="item.recipes && item.recipes.RecipesType === 2">
                  <p
                    v-if="item.recipes.AccessoryPostList.length > 0"
                    class="step-img"
                  >
                    <van-image
                      v-for="(img, k) in item.recipes.AccessoryPostList"
                      :key="k"
                      lazy-load
                      :src="img.url"
                      @click="
                        () => {
                          images = [];
                          images.push(img.url);
                          imgShow = true;
                        }
                      "
                      style="margin-right: 10px;border-radius: 6px;overflow: hidden;display: block;text-align: center;margin-bottom: 10px;"
                    />
                  </p>
                  <van-empty description="暂无数据" v-else />
                </template> -->
              </template>
              <van-empty description="暂无数据" v-else />
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <div class="my-title">
        <div class="title-label">
          <span class="span">保健知识</span>
          <span class="line"></span>
        </div>
        <div class="title-go" @click="goNoticeAll">查看全部 ></div>
      </div>
      <div class="notice">
        <template v-if="healthKnowledgeList.length > 0">
          <div
            class="my-card"
            v-for="(item, index) in healthKnowledgeList"
            :key="index"
            @click="goNoticeView(item)"
          >
            <div class="card-main">
              <div class="card_left">
                <p class="title">{{ item.Title }}</p>
                <p class="desc">{{ item.Desc }}</p>
                <div>
                  <span class="date">{{
                    $moment(item.CreateDate).format("YYYY-MM-DD HH:mm")
                  }}</span>
                </div>
              </div>
              <div class="card_right">
                <img
                  :src="item.TitleImage ? item.TitleImage : defaultImg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </template>
        <van-empty description="暂无数据" v-else />
      </div>
    </div>
    <!-- 图片预览 -->
    <van-image-preview v-model="imgShow" :images="images"> </van-image-preview>
  </div>
</template>

<script>
import {
  Tab,
  Tabs,
  Button,
  Icon,
  Calendar,
  Step,
  Steps,
  Empty,
  Image,
  ImagePreview
} from "vant";

import pic1 from "@/assets/img/recipes-pic1.png";
import pic2 from "@/assets/img/recipes-pic2.png";
import pic3 from "@/assets/img/recipes-pic3.png";
import defaultImg from "@/assets/img/defaultImg.png";
export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Calendar.name]: Calendar,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Empty.name]: Empty,
    [Image.name]: Image,
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  data() {
    return {
      defaultImg: defaultImg,
      schoolID: window.localStorage.getItem("SchoolID"),
      active: 0,
      minDate: new Date(2019, 0, 1),
      time: "",
      restList: [],
      imgShow: false,
      images: [],
      pic1: pic1,
      pic2: pic2,
      pic3: pic3,
      dataObj: null,
      dataList: [],
      imgList: [],
      recipeList: [],
      healthKnowledgeList: [],
      limit: 3, // 每页条数
      userType: window.localStorage.getItem("UserType")
    };
  },
  mounted() {
    this.getRestList();
  },
  methods: {
    // 根据索引返回星期
    returnWeekDay(i) {
      switch (i) {
        case 1:
          return "周一";
        case 2:
          return "周二";
        case 3:
          return "周三";
        case 4:
          return "周四";
        case 5:
          return "周五";
        case 6:
          return "周六";
        case 7:
          return "周日";
      }
    },
    getRestList() {
      this.recipeList = [];
      // this.dataObj = null;
      // this.dataList = [];
      // this.imgList = [];
      this.$axios
        .get("/api/Recipes/ListForApp", {
          limit: this.limit
        })
        .then(res => {
          if (res.data || res.data.length > 0) {
            // this.dataObj = res.data
            // this.dataList = res.data.imageTextList || []
            // this.imgList = res.data.accessoryList || []
            this.recipeList = res.data.recipesDtos;
            this.healthKnowledgeList = res.data.healthKnowledges;
            // for(var i=0;i<res.data.length;i++){
            //      if(res.data[i].isCurrent){
            //       this.active=i;
            //      }
            // }
          }
        });
    },
    // edit() {
    //   // 膳食编辑
    //   this.$router.push({
    //     path: "/RecipesEdit",
    //     query: {
    //       date: this.allDataList[this.active].RecipesDate
    //     }
    //   });
    // },
    selectDate(date) {
      this.time = this.$moment(date).format("YYYY-MM-DD");
      this.getRestList();
    },
    goNoticeAll() {
      this.$router.push("KnowledgeList");
    },
    goNoticeView(item) {
      this.$router.push({
        path: "KnowledgeView",
        query: {
          id: item.Id
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "./index.less";

.catImg {
  position: absolute;
  right: 3px;
  bottom: 0;
  z-index: 100;
  margin: 0px 4px;
}

.recipeList {
  position: absolute;
  top: 36%;
  width: 84%;
  left: 16%;

  div {
    width: 100%;
    position: relative;
    top: 10%;
    width: 80%;
    left: 6%;
    height: 40px;
    border: 1px solid #ccc;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(255, 170, 0, 0.2);
    color: #47afa7;
    border-radius: 10px;

    .step-times {
      padding: 6px 0px;
    }
  }

  img {
    height: 40px;
    width: 60px;
  }
}
</style>
